<template>
  <div>
    <h1>Teachers</h1>
    <a-row>
      <a-col :span="24">
        <div class="card-full">
          <div class="detail">
            <a-menu
              v-model="current"
              mode="horizontal"
            >
              <a-menu-item key="teacher">Teachers</a-menu-item>
              <a-menu-item key="class">Classes</a-menu-item>
            </a-menu>
            <TableTeacher v-if="currrent[0] === 'teacher'"/>
            <div v-else-if="current[0] === 'class'">Tes</div>
          </div>
        </div>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import TableTeacher from '@/components/app/Admin/TableTeacher'

export default {
  components: {
    TableTeacher,
  },
  data() {
    return {
      current: 'teacher',
    }
  },
}
</script>

<style>
.card-full {
  padding: 20px 20px;
  margin-top: 60px;
  width: 100%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0px 0px 10px #0419301a;
}

.detail {
  padding: 20px;
}
</style>
